
.showcase{
    background: url("../../assets/imgs/bg_2.jpg") no-repeat center;
    background-size: cover;
}

@media screen and (max-width: 768px){
    .showcase{
        background: white;
    }
}
    
