.container {
  /* background: url("../../assets/imgs/newsletter_img.jpg") no-repeat center center/cover; */
  width: 100%;

}

/* .container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
} */